<template>
  <div>
    <add-new-subject :is-add-new-subject-sidebar-active.sync="isAddNewSubjectSidebarActive" :classId="classId"
      @sendsubjectdata="sendsubjectdata" />
    <add-edit-subject :is-add-edit-subject-sidebar-active.sync="isAddEditSubjectSidebarActive"
      :Editclassdata="Editclassdata" :Editsubjectid="Editsubjectid" :Editsubjectname="Editsubjectname"
      :Editsubjectimg="Editsubjectimg" @clickeditsubjectdata="clickeditsubjectdata" />
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" v-if="removeButtons == true" @click="isAddNewSubjectSidebarActive = true">
                <span class="text-nowrap">Add Subject</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-card no-body class="mb-0">
        <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
              <b-th>No.</b-th>
              <b-th>Subject Image</b-th>
              <b-th>Subject Name</b-th>
              <b-th v-if="removeButtons == true">Public Access</b-th>
              <b-th v-if="removeButtons == true">Action</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(grade, index) in getSubject()">
              <b-tr :key="index" v-if="grade.subject">
                <b-td>{{ index + 1 }}</b-td>
                <b-td>
                  <div v-if="grade.subject ? grade.subject.subjectImg : ''">
                    <img style="width: 100px; height: 90px" class="mr-1" :src="grade.subject.subjectImg"
                      alt="subject" />
                  </div>
                </b-td>

                <b-td>
                  <div v-if="grade.subject ? grade.subject.subjectName : ''">
                    <b-link :to="{
      path:
        '/topics?class=' +
        grade._id +
        '&subject=' +
        grade.subject._id,
    }">
                      {{ grade.subject.subjectName }}
                    </b-link>
                  </div>
                </b-td>
                <b-td v-if="removeButtons == true">
                  <b-form-checkbox v-model="grade.subject.publicAccess"
                    v-on:change="statusChangePublicAccess(grade.subject)" switch size="lg"></b-form-checkbox>
                </b-td>
                <b-td v-if="removeButtons == true">
                  <b-dropdown variant="link" no-caret>
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item @click="activeEditSubjectSidebar(
      grade._id,
      grade.subject._id,
      grade.subject.subjectName,
      grade.subject.subjectImg
    )
      ">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="activeDeleteSubjectSidebar(grade._id, grade.subject._id)
      ">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { BBreadcrumb } from "bootstrap-vue";
import AddNewSubject from "./app/list/users-list/AddNewSubject";
import AddEditSubject from "./app/list/users-list/AddEditSubject.vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BBreadcrumb,
    AddNewSubject,
    AddEditSubject,
    BCard,
    BRow,
    ToastificationContent,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      Editclassdata: "",
      Editclassname: "",
      isAddNewSubjectSidebarActive: false,
      isAddEditSubjectSidebarActive: false,
      searchQuery: "",
      table_data: {},
      Editsubjectid: "",
      Editsubjectname: "",
      classId: this.$route.params.subjectsId,
      Editclassimg: "",
      Editsubjectimg: "",
      levelidedit: "",
      Editlevel: "",
      EditLevelname: "",
      EditNoofquestion: "",
      AddSubcategoryid: "",
      deletelevel: { delete_id: "" },
      perPage: 5,
      perPageOptions: [10, 25, 50, 100],
      routedata: this.$route.params.subcatId,
      alertData: "",
      deleteclassdata: {
        id: "",
      },
      deletesubjectdata: {
        class_id: "",
        subject_id: "",
      },
      toastMessage: '',
      removeButtons: "",
    };
  },
  mounted() {
    this.removeButtons = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "manage_subjects");
  },
  created() {
    this.gettabledata();
  },
  methods: {
    statusChangePublicAccess(subj) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/subject/public-access/update",
          {
            _id: subj._id,
            publicAccess: subj.publicAccess
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.gettabledata();
        });
    },
    activeDeleteSubjectSidebar: function (class_id, subject_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletesubjectdata.class_id = class_id;
          this.deletesubjectdata.subject_id = subject_id;
          axios
            .post(
              process.env.VUE_APP_API_URL + "/deletesubjectdata",
              this.deletesubjectdata
            )
            .then((response) => {
              if (response.data == "Subject not delete") {
                this.alertData =
                  response.data + ".Please delete all the Category first";
              } else {
                this.alertData = "Subject Deleted Successfully";
              }
              this.showToast("danger");
              this.gettabledata();
            });
        }
      });
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    clickeditsubjectdata: function (edit_subject_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/grade/subject/update",
          edit_subject_data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.toastMessage = "Subject Successfully Edit";
          this.toastNotification("success");
          this.gettabledata();
        }).catch(function (error) {
          this.toastMessage = "Try Again Subject Not Edit";
          this.toastNotification("danger");
        });
    },
    toastNotification(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.toastMessage,
          variant,
        },
      });
    },
    sendsubjectdata: function (subject_data) {
      axios
        .post(process.env.VUE_APP_API_URL + "/grade/subject/create", subject_data
          , {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Subject created successfully") {
            this.subjectAdded("success");
            this.gettabledata();
          } else {
            this.subjectAddedRejected("danger");
          }
        });
    },
    subjectAdded(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Subject Added",
          variant,
        },
      });
    },
    subjectAddedRejected(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Subject not added",
          variant,
        },
      });
    },
    activeEditSubjectSidebar(
      editclassid,
      editsubjectid,
      editsubjectname,
      editsubjectimg
    ) {
      this.isAddEditSubjectSidebarActive = true;
      this.Editclassdata = editclassid;
      this.Editsubjectid = editsubjectid;
      this.Editsubjectname = editsubjectname;
      this.Editsubjectimg = editsubjectimg;
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + `/getSubjects/${this.classId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.table_data = get_response.data;
        });
    },
    getSubject() {
      if (this.searchQuery.trim().length > 0) {
        return this.table_data.filter((item) => {
          if (item.subject.subjectName) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.subject.subjectName.toLowerCase().includes(v));
          }
        });
      } else {
        return this.table_data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
